<template>
  <v-row dense>
    <v-col
      cols="12"
      md="12"
    >
      <SectionTitle title="Imagens" />
    </v-col>
    <MediaImages :images="product.product_images" />

    <v-col
      cols="12"
      md="12"
    >
      <SectionTitle title="Videos" />
    </v-col>
    <MediaVideos :videos="product.product_videos" />
  </v-row>
</template>

<script>
export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    MediaVideos: () => import('./MediaVideos'),
    MediaImages: () => import('./MediaImages'),
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.product)
  },
}
</script>
